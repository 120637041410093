import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import _ from 'lodash'

import store from '@/store';
import api from '@/api';

const whiteList = ['/login']

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: () => import('./views/Login') },
  { path: '/page', component: () => import('./views/Layout'), children: [
    { path: '/page-0-6', component: () => import('./views/page/Page_0_6') },
    { path: '/page-0-6/:id', component: () => import('./views/page/Page_0_6_1') },
    { path: '/page-0-7', component: () => import('./views/page/Page_0_7') },
    { path: '/page-1-1', component: () => import('./views/page/Page_1_1') },
    { path: '/page-1-2', component: () => import('./views/page/Page_1_2') },
    { path: '/page-2-4', component: () => import('./views/page/Page_2_4') },
    { path: '/page-2-5', component: () => import('./views/page/Page_2_5') },
    { path: '/page-2-6', component: () => import('./views/page/Page_2_6') },
    { path: '/page-2-7', component: () => import('./views/page/Page_2_7') },
    { path: '/page-2-8', component: () => import('./views/page/Page_2_8') },
    { path: '/page-2-8/:id', component: () => import('./views/page/Page_2_8_1') },
    { path: '/page-4-1', component: () => import('./views/page/Page_4_1') },
    { path: '/page-4-1/:id', component: () => import('./views/page/Page_4_1_1') },
    { path: '/page-4-4', component: () => import('./views/page/Page_4_4') },
    { path: '/page-4-4/:id', component: () => import('./views/page/Page_4_4_1') },
    { path: '/page-6-1', component: () => import('./views/page/Page_6_1') },
  ]},
  { path: '*', redirect: '/page-1-1' }
]

const router = new VueRouter({
  mode: 'hash',
  routes
});

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  
  if(store.state.accountInfo) {
    next();
  } else if(to.path === '/login') {
    const res = await api.execute('account.info');
    if(res) {
      store.commit('setAccountInfo', res);
      next(to.query.redirect || '/')
    } else {
      next();
    }
  } else if(_.includes(whiteList, to.path)) {
    next();
  } else {
    const res = await api.execute('account.info');
    if(res) {
      store.commit('setAccountInfo', res);
      next();
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => NProgress.done())

export default router;