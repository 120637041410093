import _ from 'lodash';
import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    count: 0,
    accountInfo: null,
    dataQaCheck: null,
    dataQa: null,
    task: null,
    tabs: [],
  },

  mutations: {
    increment (state) {
      state.count++
    },
    setAccountInfo(state, accountInfo) {
      state.accountInfo = accountInfo;
    },
    setDataQaCheck(state, dataQaCheck) {
      state.dataQaCheck = dataQaCheck;
    },
    setDataQa(state, dataQa) {
      state.dataQa = dataQa;
    },

    setTask(state, task) {
      state.task = task;
    },
    addTab(state, tab) {
      if(!_.find(state.tabs, { label: tab.label })) {
        state.tabs = state.tabs.concat(tab);
      }
    },
    removeTab(state, label) {
      state.tabs = _.reject(state.tabs, { label });
    },
    cleanTabs(state) {
      state.tabs = [];
    } 
  }
})

export default store;