// import _ from 'lodash';
import axios from 'axios';

export default {
  async execute(...req) {
    try {
      const res = await axios.post('/api/gateway', [req]);
      return res.data[0]
    } catch(e) {
      throw e.response.data ? new Error(e.response.data) : e
    }
  },

  async executeArray(...reqArray) {
    try {
      const res = await axios.post('/api/gateway', reqArray);
      return res.data;
    } catch(e) {
      throw new Error(e.response.data)
    }
  },

}