import Vue from 'vue';
import ElementUI from 'element-ui';
import 'normalize.css/normalize.css';
import 'element-ui/lib/theme-chalk/index.css';

import moment from 'moment';
import VueCron from 'vue-cron'

import api from './api';
import App from './App.vue';
import router from './router';
import store from './store';
import './index.scss'


import '@/icons'

moment.locale('zh-cn');

Vue.use(ElementUI, { size: 'small'});
Vue.use(VueCron);


Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$log = (...args) => {
  console.log(...args)
}

new Vue({
  router, store,
  render: h => h(App),
}).$mount('#app')
